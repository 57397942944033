import React from "react";

function Header() {
	return (
		<header className="head">
			<h1>Get The Brews On</h1>
		</header>
	);
}

export default Header;
